Drupal.behaviors.productdetailsV1 = {
  attach: function (context) {
    var $prodSizeList = $('.js-product-weight-list', context);

    /* QTY dropdown update - Show Max 2 Items - JIRA:PGUB-5 - Start*/
    $(document).trigger('product:showTwoItemsInQtySelection', window.PRODUCT_ID);
    if ($prodSizeList.length) {
      // Apply selectBox to product weight dropdown
      $prodSizeList.selectBox();
    }
    $(document).on('product:quickshop', function () {
      $('.js-product-weight-list').selectBox();
    });

    // Update active sku related images in SPP gallery on load
    $('.js-sku-menu').change();
  }
};
$(document).trigger('email_signup:js-pony_popup');
